import {useEffect, useState} from "react";

import styled from 'styled-components'

import krest from '../img/krest.svg'

const Button = styled.button`
  border: none;
  background-color: inherit;
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  user-select: none;
  outline: 1px solid black;

  &:nth-child(1) {
    justify-self: left;
  }

  &:active {
    background-color: #c8c9ce;
  }


  img {
    height: 20px;
    width: 20px;
  }
`

let timeout

window.oncontextmenu = function (event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
};

function Field({element, field, callmodal}) {
    const [state, setState] = useState(null)

    const [counter, setCounter] = useState(0)

    useEffect(() => {
        if (counter > 0) {
            timeout = setTimeout(() => callmodal(handleSetState), 1000)
        }
    }, [counter])



    useEffect(() => {
        if (element !== null) {
            setState(element.state[field].value)
        }
    }, [])

    const handleClick = () => {
        if (state === null) {
            setState(krest)
            element.state[field].value = krest

        } else {
            setState(null)
            element.state[field].value = null
        }
        localStorage.setItem(element.name, JSON.stringify(element))
    }

    const handleMouseDown = () => {
        setCounter(5)
    }

    const handleTouchStart = () => {
        setCounter(5)
    }

    const resetCounter = () => {
        setCounter(0)
        clearTimeout(timeout)
    }

    const handleSetState = (img) => {
        resetCounter()
        setState(img)
        element.state[field].value = img
        localStorage.setItem(element.name, JSON.stringify(element))
    }

    const handleMouseUp = (event) => {
        event.preventDefault()
        resetCounter()
    }

    const handleDoubleClick = (event) => {
        event.preventDefault()
        resetCounter()
        handleClick()
    }

    const handleTouchEnd = (e) => {
        resetCounter()

    }

    return (
        <Button
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onDoubleClick={handleDoubleClick}
            onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}
        >
            {state !== null && <img src={state} alt={""}/>}
        </Button>
    )
}

export default Field
