import styled from 'styled-components'


import cluedoImg  from '../img/cluedo.jpg'
import Menu from "./Menu";
import {useEffect, useRef, useState} from "react";

const Container = styled.div`
  padding: 5px 10px;
  background-color: black;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr;
  justify-content: center;
  align-items: center;
  
`

const BurgerLine = styled.div`
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: inherit;
  background-color: white;
`

const Burger = styled.button`
  outline: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
  position: relative;
  border-radius: 3px;
  width: 28px;
  height: 20px;
  cursor: pointer;
  user-select: none;

  {BurgerLine}:nth-child(1) {
    top: 0px;
  }

  //{BurgerLine}:nth-child(2) {
  //  top: 50%;
  //}

  {BurgerLine}:nth-child(3) {
    bottom: 0px;
    width: 50%;
  }
`

const Img = styled.img`
  height: 30px;
  align-self: center;
  justify-self: center;
`


function Header() {
   const [state, setState] = useState(false)
    const [animation, setAnimation] = useState(null)
    const burgerEl = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
    }, []);

    const handleOutsideClick = (event) => {
        if (event && event.target !== null && burgerEl && !burgerEl.current.contains(event.target)) {
                setAnimation(false)
                setTimeout(() => {
                    setState(false)
                }, 800)
        }
    }

    const burgerClick = (event) => {
        event.preventDefault()
        setState(true)
        setAnimation(true)
    }

    return (
        <Container>
            <Burger ref={burgerEl} onClick={burgerClick} >
                <BurgerLine/>
                <BurgerLine />
                <BurgerLine />
            </Burger>
            <Img src={cluedoImg} alt={"cluedo"}/>
            {state === true && <Menu animation={animation} /> }
        </Container>
    )
}
export default Header
