
import styled from 'styled-components'
import Field from "./Field";
import {useState} from "react";

import krest from '../img/krest.svg'
export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(7, 25px);
  user-select: none;
  grid-gap: 5px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }


  p {
    background-color: white;
    padding: 3px 5px;
    margin: 0;
    align-self: center;
    min-width: 100px;
    outline: 1px solid black;
    font-size: 14px;
    user-select: none;
  }
`
let f
function RowTable( {cardObj, callmodal} ) {
    return (
        <Row>
            <p>{cardObj.name}</p>

            {cardObj.state.map(s => {
                return(
                    <Field
                        id={s.key + cardObj.name}
                        element={cardObj}
                        field={s.key}
                        callmodal={callmodal}
                    />
                )
            })}
        </Row>
    )

}
export default RowTable
