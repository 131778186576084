import logo from './logo.svg';
import './App.css';
import Header from "./header/Header";
import Main from "./main/Main";

import styled from 'styled-components'
import {useState} from "react";


import krest from './img/krest.svg'
import galka from './img/galka.svg'
import vopros from './img/vopros.svg'
import point from './img/point.svg'

const Div = styled.div`
  position: relative;
`


const MenuElements = styled.div`
  //margin: 50vh auto;
  align-self: center;
  max-width: max-content;
  display: flex;
  flex-direction: column;

  button {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background-color: transparent;

    svg {
      width: 30px;
      height: 30px;
    }

    &:hover, &:active {
      scale: 1.2;
    }
  }
`

const Img = styled.img`
  max-width: 100px;
  max-height: 100px;
  width: 100%;
  box-shadow: black 2px 2px 2px 2px;
  background-color: white;

  &:hover {
    box-shadow: black 2px 2px 10px 5px;
  }

  &:active {
    scale: 1.1;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`


const ModalWindow = styled.div`
  position: fixed;
  background-color: inherit;
  //width: 100vh;
  width: 100%;
  max-width: 550px;
  height: 100vh;
  //height: 100%;
  background-color: #dae4e7;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 100;


  visibility: ${prop => {
    if (prop.modal === true) {
      return "visible";
    } else {
      return 'hidden';
    }
  }};

  animation-name: ${prop => {
    if (prop.animation === true) {
      return "modalWin";
    } else if (prop.animation === false){
      return 'modalWinClose';
    }
  }};
  animation-duration: 0.4s;

  @keyframes modalWin {
    0% {
    //  transform: translateY(-100vh);
      opacity: 0;
    }
    100% {
   //   transform: translateY(0vh);
      opacity: 1;
    }
  }
  @keyframes modalWinClose {
    0% {
  //    transform: translateY(0vh);
      opacity: 1;
    }
    100% {
    //  transform: translateY(-100vh);
      opacity: 0;
    }
  }

`
let func;

function App() {
    const [modal, setModal] = useState(false)
    const [animation, setAnimation] = useState(null)
    const callModal = (f) => {
        setModal(true)
        setAnimation(true)
        func = f
    }

    const handleClick = (img) => {
        setAnimation(null)
        setModal(false)
        func(img)
    }

    const buttonClick = () => {
        // setModal(false)
        setAnimation(false)
        setTimeout(() => { setModal(false); setAnimation(null)}, 400)
    }
    return (
        <Div>
            <Header/>
            <Main callmodal={callModal}/>
            <ModalWindow modal={modal} animation={animation}>
                <MenuElements>
                    <Img src={krest} onClick={e => handleClick(krest)}/>
                    <Img src={galka} onClick={e => handleClick(galka)}/>
                    <Img src={vopros} onClick={e => handleClick(vopros)}/>
                    <Img src={point} onClick={e => handleClick(point)}/>

                    <button onClick={buttonClick}>
                        <svg width="48" height="48" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M4.11 2.697L2.698 4.11 6.586 8l-3.89 3.89 1.415 1.413L8 9.414l3.89 3.89 1.413-1.415L9.414 8l3.89-3.89-1.415-1.413L8 6.586l-3.89-3.89z"
                                  fill="#000"></path>
                        </svg>
                    </button>
                </MenuElements>

            </ModalWindow>
        </Div>
    );
}

export default App;
