import styled from 'styled-components'
import RowTable, {Row} from "./RowTable";
import {useEffect, useRef} from "react";

const Div = styled.div`
  position: relative;
  background-color: #4040fa;
  padding: 10px 20px;
`

const TableRow = styled(Row)`
  margin-top: 5px;
    p {
      font-weight: 700;
      font-size: 15px;
      padding: 1px 5px;
      background-color: white;
      user-select: none;
    }
  
  input {
    border: none;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
    padding: 1px 7px;
    background-color: white;
    outline: 1px solid black;
    
  }
  
  h2 {
    padding: 1px 5px;
    margin: 0;
    font-weight: 700;
    color: white;
    font-size: 15px;
    align-self: center;
  }
  
`

const array = () => {
    let arr = []
    for (let i =0; i < 7; i++) {
        arr.push({key: i, value: null})
    }
    return arr;
}

const suspects = ['Грин', 'Мастард', 'Оркид', 'Пикок', 'Плам', 'Скарлет'];
const guns = ['Подсвечник', 'Кинжал', 'Свинцовая труба', 'Револьвер', 'Веревка', 'Гаечный ключ'];
const places = ['Бальный зал', 'Бильярдная', 'Зимний сад', 'Столовая', 'Холл', 'Кухня', 'Библиотека', 'Гостиная', 'Кабинет'];
const ids = ['inp1', 'inp2', 'inp3', 'inp4', 'inp5', 'inp6']
const rows = (category) => {
    return category.map(c => {
        let el = JSON.parse(localStorage.getItem(c));
        if (el != null) {
            return el
        }
        return {name: c, state: array()}
    })
}
function Main( {callmodal} ) {

    useEffect(() => {
        ids.forEach(i => {
           let event = localStorage.getItem(i)
            if (event != null) {
                document.getElementById(i).value = event;
            }
        })
    }, [])


    const inputHandler = (e) => {
        if (e.target.value.length > 0) {
            e.target.value = e.target.value[e.target.value.length - 1]
        }
        localStorage.setItem(e.target.id, e.target.value)
    }

    return (
            <Div>

                <TableRow>
                    <h2>ИГРОКИ</h2>
                    <span></span>
                    {ids.map(i => {
                       return <input id={i} onChange={e => inputHandler(e)}/>
                    })}
                </TableRow>

                <TableRow>
                    <p>КТО?</p>
                </TableRow>

                {rows(suspects).map(s => {
                    return <RowTable id={s.name} cardObj={s} callmodal={callmodal}/>
                })}


                <TableRow>
                    <p>ЧЕМ?</p>
                </TableRow>
                {rows(guns).map(s => {
                    return <RowTable id={s.name} cardObj={s} callmodal={callmodal}/>
                })}

                <TableRow>
                    <p>ГДЕ?</p>
                </TableRow>
                {rows(places).map(s => {
                    return <RowTable id={s.name} cardObj={s} callmodal={callmodal}/>
                })}
            </Div>
    )

}

export default Main
