import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  position: absolute;
  top: 40px;
  width: 350px;
  z-index: 9999;
  animation-name: ${prop => {
    if (prop.animation === true) {
      return "open";
    } else if (prop.animation === false){
      return 'close';
    }
  }};
  
  animation-duration: 1s;

  @keyframes open {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  @keyframes close {
    from {
      transform: translateY(0px);
      opacity: 1;
    }
    to {
      transform: translateY(-30px);
      opacity: 0;
    }
  }
`

const Btn = styled.button`
  display: flex;
  flex-direction: column;
  background-color: black;
  position: absolute;
  color: white;
  padding: 20px;
  border: none;
  cursor: pointer;

  &:active {
    background-color: gray;
  }

  &:hover {
    background-color: #46494d;
  }
`

function Menu({animation}) {
    const resetGame = () => {
        localStorage.clear()
        window.location.reload();
    }
    return (
        <Div animation={animation}>
            <Btn onClick={e => resetGame()}>Новая игра</Btn>
        </Div>
    )

}
export default Menu
